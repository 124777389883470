<template>
    <div class="certification">
        <div v-if="first === true">
            <div style="display: flex; margin-bottom: 40px">
                <div class="name-card">
                    <span style="color: #999999">真实姓名：</span>
                    <span>{{nameInfo.realName}}<span v-if="user.realNameStatus > 0">******</span></span>
                </div>
                <div class="name-card">
                  <span style="color: #999999">身份证号：</span>
                  <span>{{nameInfo.idCardNo}}<span v-if="user.realNameStatus > 0">*************</span></span>
                </div>
                <div class="hand op" style="margin-right: 30px" @click="first = false" v-if="user.authenticationStatus < 1">
                    <img src="../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                </div>
            </div>

<!--            <div style="margin-bottom: 20px; text-align: center" v-if="user.realNameStatus == 0">-->
<!--&lt;!&ndash;                <el-button type="primary" @click="initFaceVerify">提交信息</el-button>&ndash;&gt;-->
<!--                <span style="color: #6d737b" v-if="JSON.stringify(faceStatus) == '{}'">*请进行人脸识别，完成实名认证</span>-->
<!--                <span style="margin-left: 20px; color: #cc0000" v-else>{{faceStatus.msg}}</span>-->
<!--            </div>-->
            <div style="margin-bottom: 20px; text-align: center" v-if="user.realNameStatus == 1">
                <span style=" color: #6d737b;color: #3d97d4;" v-if="JSON.stringify(faceStatus) == '{}'">实名认证审核中，请耐心等待</span>
            </div>
            <div style="margin-bottom: 20px; text-align: center" v-if="user.realNameStatus == 2">
                <div style="margin: 200px 0" v-if="JSON.stringify(faceStatus) == '{}'">
                  <img src="~@/assets/imgs/index/authIcon.png" style="width: 120px; height: 120px">
                  <div style="margin: 20px; font-size: 18px">已完成实名认证</div>
                  <span v-if="user.authenticationStatus === 1" class="hand op" @click="nextAuth"  style="width: 140px; display: inline-block; text-align: center; color: #FFFFFF; background-color:#2E73FF;font-size: 16px; height: 36px; border-radius: 5px; line-height: 36px">进行专业认证</span>
                  <span v-if="user.authenticationStatus > 1" class="hand op" @click="nextAuth"  style="width: 140px; display: inline-block; text-align: center; color: #FFFFFF; background-color:#2E73FF;font-size: 16px; height: 36px; border-radius: 5px; line-height: 36px">完善专业认证</span>
                </div>
            </div>
            <div v-if="qrShow" style="text-align: center">
                <vue-qr
                  :text="qr"
                  :size="250"
                  :logoScale="0.2">
                </vue-qr>
            </div>

        </div>

        <div v-if="first === false">
            <el-form :model="nameForm" :rules="nameRules" ref="resumeForm">
                <div style="display: flex; margin-bottom: 20px">
                    <div style="flex: 1">
                        <el-form-item prop="realName">
                            <div class="title">真实姓名</div>
                            <el-input class="input" v-model="nameForm.realName" maxlength="20"></el-input>
                        </el-form-item>
                    </div>
                    <div style="flex: 1; margin-left: 30px">
                        <el-form-item prop="idCardNo">
                            <div class="title">身份证号</div>
                            <el-input class="input" v-model="nameForm.idCardNo" maxlength="18"></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <img src="../../../assets/imgs/circle/hint.png" class="hand op" style="width: 30px; height: 30px"/>
                    </div>
                </div>
                <div style="margin-left: 20%; margin-bottom: 30px">身份证信息仅用于认证身份，实名、身份证号码、身份证照片均不对外展示</div>

                <div style="text-align: end">
                    <el-button class="cancel-btn" @click="cancelInfo">取消</el-button>
                    <el-button class="save-btn" @click="updateInfo">上传</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Certification",
        components: {},
        props: {
            isOrgan: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },

        data() {
            return {
                first: true,
                nameInfo: {
                    realName: '',
                    idCardNo: '',
                    userType: 1
                },
                nameForm: {
                    realName: '',
                    idCardNo: '',
                    userType: 1
                },
                nameRules: {
                    realName: [
                        { required: true, message: '请输入真实姓名', trigger: 'blur' },
                        { pattern: /^[\u4E00-\u9FA5]{2,10}(·[\u4E00-\u9FA5]{2,10}){0,2}$/, message: '请输入真实姓名', trigger: 'blur'},
                    ],
                    idCardNo: [
                        { required: true, message: '请输入正确身份证号码', trigger: 'blur' },
                        { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确身份证号码', trigger: 'blur'},
                        { min: 18, max: 18, message: '身份证号码格式错误', trigger: 'blur'}
                    ],
                },

                imgFront: '',
                imgBack: '',
                qr:'',
                qrShow:false,
                faceStatus: {},

                updateShowInfo: false,
                visibleInfo: '1',
                centerDialogVisible:false,
                wx_code:"",
                wx_openid:"",

                // 绑定手机号
                type: '',
                showPhoneWin: false,
            }
        },

        computed: {
            user() {
                return this.$store.state.user;
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

        methods: {
            //获取openID
            getOpenid:function () {
                var that = this;
                that.newApi.getWxOpenid(that.wx_code).then((res)=>{
                    if(res.openid != "" && typeof res.openid != "undefined"){
                        that.wx_openid = res.openid;
                        let pa = {};
                        pa.openid = that.wx_openid;
                        that.newApi.getClickBindWxOpenid(pa).then((ret)=>{
                            if(ret.isSuccess == 1){
                                that.utils.sus("绑定成功");
                            }
                        })
                    }
                })
            },

            /**
             * 人脸识别初始化
             * @constructor
             */
            initFaceVerify(){
                var that = this;
                if (!that.nameInfo.realName || !that.nameInfo.idCardNo) {
                    that.utils.msg('请先填写身份认证信息')
                    return
                }
                var MetaInfo =window.getMetaInfo();
                // console.log(MetaInfo);
                that.newApi.getInitFaceVerify({"MetaInfo":JSON.stringify(MetaInfo),"realName":that.nameInfo.realName, "idCardNo":that.nameInfo.idCardNo,"token":localStorage.getItem("token")}).then(res =>{
                  if (res.isSuccess === 1) {
                    that.first = true
                    console.log(this.videoCheck())
                    if (this.videoCheck()){
                      window.location.href = res.data.resultObject.certifyUrl
                    }else{
                      this.qr = res.data.resultObject.certifyUrl;
                      this.qrShow = true;
                    }
                  }

                    // console.log(res);
                })
            },
            videoCheck(){
                var deviceList = [];
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    devices.forEach(device => {
                        deviceList.push(device.kind);
                    });
                    if (deviceList.indexOf("videoinput") == "-1") {
                        console.info("没有摄像头");
                        return false;
                    } else {
                        console.info("有摄像头");
                        return true;
                    }
                })
                    .catch(function (err) {
                        alert(err.name + ": " + err.message);
                    });
            },

            // 取消
            cancelInfo() {
                this.nameForm = {
                    realName: '',
                    idCardNo: '',
                    userType: 1
                };
                this.first = true
            },

            // 上传
            updateInfo() {
                var that = this;
                that.$refs.resumeForm.validate((valid) => {
                    if (valid) {
                      that.nameInfo.realName = that.nameForm.realName
                      that.nameInfo.idCardNo = that.nameForm.idCardNo
                      that.initFaceVerify()
                    }
                })

            },

            // 继续专业认证
            nextAuth() {
              this.a('/archives')
            },
        },

      created() {
          if (this.user.authenticationStatus === 0) {
            this.first = false
          }
      },

      mounted() {
        const oScript = document.createElement('script');
        oScript.type = 'text/javascript';
        oScript.src = 'https://cn-shanghai-aliyun-cloudauth.oss-cn-shanghai.aliyuncs.com/web_sdk_js/jsvm_all.js';
        document.body.appendChild(oScript);

          this.wx_code = this.utils.getUrlParams("code");
          if(this.wx_code != "" && typeof this.wx_code != "undefined"){
              this.getOpenid();
          }
          this.$EventBus.$on('faceData', (data) => {
              this.faceStatus = data;
              if (this.faceStatus.code == '200') {
                  this.utils.sus(this.faceStatus.msg);
                  this.qrShow = false
                  location.reload()
              } else {
                  this.utils.sus(this.faceStatus.msg);
                  this.qrShow = false
                  this.initFaceVerify()
              }
          })
      }

    }
</script>

<style scoped>

    .title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .content {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .input {
        width: 280px;
    }

    .save-btn {
        width: 68px;
        height: 38px;
        color: #fff;
        background-color: #1C7EFF;
        border-radius: 5px;
    }

    .cancel-btn {
        width: 68px;
        height: 38px;
        margin-right: 18px;
        background-color: #E4E6EB;
        border-radius: 5px;
    }

    .name-card {
      width: 460px;
      height: 26px;
      padding: 16px 20px;
      font-size: 18px;
      font-weight: 600;
      margin-right: 20px;
      box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
      border-radius: 5px 5px 5px 5px;
    }

    .upload-demo {
        vertical-align: middle;
    }

    .el-icon-plus {
        color: #2970FF;
        font-size: 44px;
        font-weight: 600;
        text-shadow: 0px 3px 6px rgba(0,0,0,0.16);
        margin: 100px 0 14px 0;
    }

    .resume-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .wx-btn {
        width: 140px;
        height: 40px;
        padding: 8px 10px;
        margin-right: 10px;
        border-radius: 5px;
    }

    .wx-icon {
        width: 22px;
        height: 22px;
        vertical-align: middle;
    }

    .wx-cont {
        font-size: 16px;
        font-weight: 600;
        vertical-align: middle;
    }



    /*公共*/
    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }

    /deep/ .el-upload-dragger {
        width: 500px;
        height: 300px;
        background-color: #FBFBFB;
    }
</style>
